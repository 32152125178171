import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_@upstash+redis@1.34.0_@vercel+kv@2.0.0_e_q5ecaxjkwl4ddkmwitcouchg4e/node_modules/nuxt/dist/pages/runtime/validate.js";
import _0_45auth_45validate_45token_45global from "/vercel/path0/middleware/0.authValidateToken.global.ts";
import _1_45auth_45global from "/vercel/path0/middleware/1.auth.global.js";
import _2_45utm_45global from "/vercel/path0/middleware/2.utm.global.js";
import navigation_45global from "/vercel/path0/middleware/navigation.global.js";
export const globalMiddleware = [
  validate,
  _0_45auth_45validate_45token_45global,
  _1_45auth_45global,
  _2_45utm_45global,
  navigation_45global
]
export const namedMiddleware = {
  "house-transaction": () => import("/vercel/path0/middleware/house-transaction.js"),
  house: () => import("/vercel/path0/middleware/house.js"),
  login: () => import("/vercel/path0/middleware/login.ts"),
  "property-sales": () => import("/vercel/path0/middleware/property-sales.js"),
  "redirect-wishlist": () => import("/vercel/path0/middleware/redirect-wishlist.ts"),
  "search-destination-filter-validation": () => import("/vercel/path0/middleware/search-destination-filter-validation.js"),
  "search-destination-validation": () => import("/vercel/path0/middleware/search-destination-validation.js"),
  "stay-proposal-edit": () => import("/vercel/path0/middleware/stay-proposal-edit.js"),
  "unapproved-contract-information": () => import("/vercel/path0/middleware/unapproved-contract-information.js")
}