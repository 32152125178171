<script setup lang="ts">
import type { NuxtError } from '#app'
import type { Advisor } from '~/types/reservationAgreement/types'

defineProps<{
  data: {
    propertyName: string
    propertyPhoto: string
    advisor: Advisor
  }
  error: NuxtError
}>()

const advisorModal = ref(false)
</script>

<template>
  <div class="lc-container md:flex my-8 md:my-28 items-center">
    <div class="w-full md:w-2/3">
      <div class="w-full md:w-4/5">
        <h1 class="text-4xl md:text-7xl mb-8">
          {{ $t('errorPage.clientContractNotSignedByOwner.title') }}
        </h1>
        <p>
          {{ $t('errorPage.clientContractNotSignedByOwner.text') }}
        </p>

        <BaseButton
          color="secondary"
          data-testid="advisorModal"
          data-cy="advisorModal"
          @click="advisorModal = true"
        >
          {{ $t('stayProposal.page.contactMyAdvisor') }}
        </BaseButton>
      </div>
    </div>
    <div class="hidden md:block md:w-1/3">
      <BaseNuxtImg
        :alt="data.propertyName"
        width="500"
        height="500"
        :src="data.propertyPhoto"
        class="rounded"
      />
    </div>

    <BaseContactModal
      v-model="advisorModal"
      :firstname="data.advisor.firstName"
      :lastname="data.advisor.lastName"
      :phone="data.advisor.phone"
      :email="data.advisor.email"
    />
  </div>
</template>
