<script setup lang="ts">
import type { UpdateInquiryResponse } from 'lc-services/types'

const { t } = useI18n()
const { userIsPartner } = useAuth()

const props = withDefaults(
  defineProps<{
    buttonTranslation?: string
    mutation: (payload: UpdateInquiryResponse) => Promise<void>
  }>(),
  {
    buttonTranslation: 'inquiry.success.update',
  },
)
defineSlots<{ header(): any }>()

const comment = ref('')
const group = reactive({
  adults: {
    category: t('inquiry.success.adults.category'),
    description: t('inquiry.success.adults.description'),
    count: 0,
  },
  children: {
    category: t('inquiry.success.children.category'),
    description: t('inquiry.success.children.description'),
    count: 0,
  },
  babies: {
    category: t('inquiry.success.babies.category'),
    description: t('inquiry.success.babies.description'),
    count: 0,
  },
})

const isSurveyBlank = computed(() => {
  const hasPeople = Object.values(group)
    .map((type) => type.count)
    .some((count) => count)

  return !hasPeople && !comment.value
})

const onSubmit = async () => {
  if (!isSurveyBlank.value) {
    await props.mutation({
      clientComment: comment.value,
      numberOfAdults: group.adults.count,
      numberOfBabies: group.babies.count,
      numberOfChildren: group.children.count,
    })
  }
}
</script>

<template>
  <div>
    <slot name="header">
      <div>
        <div class="flex mb-6">
          <h5 class="text-xl mb-0 mr-4">
            {{ $t('inquiry.success.title') }}
          </h5>
          <BaseIcon class="flex-none text-secondary-400" name="checkCircle" />
        </div>

        <p class="text-md mb-2">
          {{ $t('inquiry.success.content') }}
        </p>
      </div>
    </slot>

    <div class="mb-6">
      <div
        v-for="(type, key) in group"
        :key="key"
        :data-testid="`inquiry-success__${key}`"
        class="flex justify-between py-4 border-b border-gray-200"
      >
        <div class="flex flex-wrap text-sm items-center mr-2">
          <p :id="`category-${key}`" class="mb-0 mr-2">
            {{ type.category }}
          </p>
          <p class="mb-0 text-gray-600">
            {{ type.description }}
          </p>
        </div>
        <div class="flex flex-none items-center w-32">
          <base-form-components-base-input-number
            v-model="type.count"
            :name="key"
            wrapper-class="w-full"
          />
        </div>
      </div>
    </div>

    <BaseFormComponentsBaseTextarea
      id="inquiry-success-comment"
      v-model="comment"
      id-form="success-step"
      name="comment"
      :placeholder="
        userIsPartner
          ? $t('inquiry.success.commentPlaceholderB2B2C')
          : $t('inquiry.success.commentPlaceholder')
      "
      rows="3"
      wrapper-class="w-full mb-10"
    >
      <template #label>
        <label
          class="flex items-baseline text-md font-medium"
          for="inquiry-success-comment"
        >
          {{ $t('inquiry.success.addComment') }}
        </label>
      </template>
    </BaseFormComponentsBaseTextarea>

    <div>
      <BaseButton
        :disabled="isSurveyBlank"
        class="w-full"
        color="secondary"
        type="submit"
        @click="onSubmit"
      >
        {{ $t(buttonTranslation) }}
      </BaseButton>
    </div>
  </div>
</template>
