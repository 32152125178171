import revive_payload_client_LGE4ugz8qW from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_@upstash+redis@1.34.0_@vercel+kv@2.0.0_e_q5ecaxjkwl4ddkmwitcouchg4e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_1A5KilnOpu from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_@upstash+redis@1.34.0_@vercel+kv@2.0.0_e_q5ecaxjkwl4ddkmwitcouchg4e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_WzCcnMKTo9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_@upstash+redis@1.34.0_@vercel+kv@2.0.0_e_q5ecaxjkwl4ddkmwitcouchg4e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_gS5VvuRqAC from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.16_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.4_terser@5.3_bxkmljpblca37ngsty7npvxdum/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_gaf2RNWdNA from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_@upstash+redis@1.34.0_@vercel+kv@2.0.0_e_q5ecaxjkwl4ddkmwitcouchg4e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_gUePbfdigy from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_@upstash+redis@1.34.0_@vercel+kv@2.0.0_e_q5ecaxjkwl4ddkmwitcouchg4e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_Dw8p8fdlqL from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_@upstash+redis@1.34.0_@vercel+kv@2.0.0_e_q5ecaxjkwl4ddkmwitcouchg4e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_gSgWKTHVD8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_@upstash+redis@1.34.0_@vercel+kv@2.0.0_e_q5ecaxjkwl4ddkmwitcouchg4e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_Kx45jCVmcE from "/vercel/path0/node_modules/.pnpm/nuxt-vercel-analytics@1.0.3_magicast@0.3.5_rollup@4.21.2/node_modules/nuxt-vercel-analytics/dist/runtime/plugin.client.mjs";
import switch_locale_path_ssr_lZW38f0L3G from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_magicast@0.3.5_rollup@4.21.2_vue@3.5.2_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_hAliYPhoXH from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_magicast@0.3.5_rollup@4.21.2_vue@3.5.2_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_b2u8UtPtFs from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.2.2_@parcel+watcher@2.4.1_@types+node@22.5.4_@upstash+redis@1.34.0_@vercel+k_2hgkcbz5opyvbfbhhh5wn2d4sm/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_tvtQorOJON from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.21.2/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import _00_cookies_UxGbIYP5Lj from "/vercel/path0/plugins/00.cookies.ts";
import _01_api_GIiciWaEWX from "/vercel/path0/plugins/01.api.ts";
import _03_lcServices_qQLkq7GlFA from "/vercel/path0/plugins/03.lcServices.ts";
import _06_gtm_client_7NTV0XxZro from "/vercel/path0/plugins/06.gtm.client.ts";
import _07_getBrowserLanguage_S8Ci00VXAB from "/vercel/path0/plugins/07.getBrowserLanguage.js";
import _08_getUserCurrency_iC9uklmOzO from "/vercel/path0/plugins/08.getUserCurrency.js";
import _09_strapi_api_LaKIr10H54 from "/vercel/path0/plugins/09.strapi-api.js";
import _10_experiment_DuBl1zzse6 from "/vercel/path0/plugins/10.experiment.ts";
import actioncable_client_7QyhyAlEbJ from "/vercel/path0/plugins/actioncable.client.js";
import analytics_client_mZYnNRQBH8 from "/vercel/path0/plugins/analytics.client.js";
import aos_client_rDSYU13oOX from "/vercel/path0/plugins/aos.client.ts";
import clickOutside_client_Zc9fkH3Rh6 from "/vercel/path0/plugins/clickOutside.client.ts";
import maska_client_D8wFsCvpJf from "/vercel/path0/plugins/maska.client.ts";
import vee_validate_K3WwmJMPDb from "/vercel/path0/plugins/vee-validate.js";
export default [
  revive_payload_client_LGE4ugz8qW,
  unhead_1A5KilnOpu,
  router_WzCcnMKTo9,
  _0_siteConfig_gS5VvuRqAC,
  payload_client_gaf2RNWdNA,
  navigation_repaint_client_gUePbfdigy,
  chunk_reload_client_Dw8p8fdlqL,
  components_plugin_KR1HBZs4kY,
  prefetch_client_gSgWKTHVD8,
  plugin_client_Kx45jCVmcE,
  switch_locale_path_ssr_lZW38f0L3G,
  i18n_hAliYPhoXH,
  plugin_b2u8UtPtFs,
  plugin_tvtQorOJON,
  _00_cookies_UxGbIYP5Lj,
  _01_api_GIiciWaEWX,
  _03_lcServices_qQLkq7GlFA,
  _06_gtm_client_7NTV0XxZro,
  _07_getBrowserLanguage_S8Ci00VXAB,
  _08_getUserCurrency_iC9uklmOzO,
  _09_strapi_api_LaKIr10H54,
  _10_experiment_DuBl1zzse6,
  actioncable_client_7QyhyAlEbJ,
  analytics_client_mZYnNRQBH8,
  aos_client_rDSYU13oOX,
  clickOutside_client_Zc9fkH3Rh6,
  maska_client_D8wFsCvpJf,
  vee_validate_K3WwmJMPDb
]