<script setup lang="ts">
const emits = defineEmits<{
  'close-tooltip': []
}>()

const props = withDefaults(
  defineProps<{
    blockFull?: boolean
    clickable?: boolean
    contentClass?: string
    closeClass?: string
    noticeTooltip?: boolean
    position?: 'top' | 'left' | 'right' | 'bottom' | 'bottom-right'
    rounded?: boolean
    show?: boolean
    size?: 'medium' | 'small'
    variant?: 'grey' | 'white'
  }>(),
  {
    blockFull: false,
    clickable: false,
    contentClass: '',
    closeClass: '',
    noticeTooltip: false,
    position: 'top',
    rounded: false,
    show: false,
    size: 'medium',
    variant: 'grey',
  },
)
defineSlots<{ element(): any; text(): any }>()

const showTooltip = ref(!props.clickable)

const tooltipClass = computed(() => {
  const baseClass = 'lc_tooltip-content'

  return [
    baseClass,
    `${baseClass}--${props.position}`,
    `${baseClass}--${props.size}`,
    `${baseClass}--${props.variant}`,
    `${baseClass}--absolute`,
    { rounded: props.rounded },
    props.blockFull ? `${baseClass}--full` : null,
  ]
})

const toggleTooltip = () => {
  emits('close-tooltip')
  showTooltip.value = !showTooltip.value
}
</script>

<template>
  <div
    :class="[
      'relative lc_tooltip',
      {
        'lc_tooltip--appear': showTooltip && (clickable || noticeTooltip),
        'lc_tooltip--hover': showTooltip,
      },
    ]"
    @click.stop="toggleTooltip"
  >
    <slot name="element" />

    <div
      v-if="show && $slots['text']"
      data-testid="tooltip"
      :class="[tooltipClass, contentClass]"
    >
      <slot name="text" />

      <BaseIcon
        v-if="clickable || noticeTooltip"
        :class="['absolute cursor-pointer right-3 top-4', closeClass]"
        name="cancel"
        @click.stop="toggleTooltip"
      />
    </div>
  </div>
</template>

<style scoped>
.lc_tooltip-content {
  margin: 1.5rem 0 0 0;
}

.lc_tooltip-content--absolute {
  @apply opacity-0 invisible absolute w-max text-white font-normal transition-opacity m-0 z-fixed;
  display: inline-table; /* hack safari, because small tooltip is truncate */
}

.lc_tooltip-content--grey {
  @apply bg-gray-700 border border-gray-700;
}

.lc_tooltip-content--white {
  @apply bg-white border border-gray-300 text-gray-700 shadow-sm;
}

.lc_tooltip--appear .lc_tooltip-content--absolute,
.lc_tooltip--hover:hover .lc_tooltip-content--absolute {
  @apply opacity-100 visible;
}

.lc_tooltip--appear .lc_tooltip-content:before,
.lc_tooltip--hover:hover .lc_tooltip-content:before {
  @apply absolute;
  content: '';
  height: 0.8rem;
  width: 0.8rem;
}

.lc_tooltip--appear .lc_tooltip-content--grey:before,
.lc_tooltip--hover:hover .lc_tooltip-content--grey:before {
  @apply bg-gray-700 border-gray-700;
}

.lc_tooltip--appear .lc_tooltip-content--white:before,
.lc_tooltip--hover:hover .lc_tooltip-content--white:before {
  @apply bg-white border-gray-300;
}

.lc_tooltip-content--top {
  @apply my-0 mx-auto left-0;
  bottom: calc(100% + 10px);
}

@screen md {
  .lc_tooltip-content--top {
    @apply left-1/2;
    transform: translateX(-50%);
  }
}

.lc_tooltip-content--top:before {
  @apply border-b border-r bottom-[-2px] left-[15px];
  transform: translateX(-50%) translateY(calc(50% - 1px)) rotate(45deg);
}

@screen md {
  .lc_tooltip-content--top:before {
    @apply left-1/2;
  }
}

.lc_tooltip-content--bottom {
  @apply my-0 mx-auto left-0;
  top: calc(100% + 10px);
}

@screen md {
  .lc_tooltip-content--bottom {
    @apply left-1/2;
    transform: translateX(-50%);
  }
}

.lc_tooltip-content--bottom:before {
  @apply border-l border-t top-0;
  left: 15px;
  transform: translateX(-50%) translateY(calc(-50% - 1px)) rotate(45deg);
}

@screen md {
  .lc_tooltip-content--bottom:before {
    @apply left-1/2;
  }
}

.lc_tooltip-content--bottom-right {
  @apply my-0 mx-auto left-auto -right-4;
  top: calc(100% + 10px);
  transform: translateX(0%);
}

.lc_tooltip-content--bottom-right:before {
  @apply border-l border-t top-0;
  right: 15px;
  transform: translateX(-50%) translateY(calc(-50% - 1px)) rotate(45deg);
}

.lc_tooltip-content--right {
  @apply top-1/2;
  left: calc(100% + 10px);
  transform: translateY(-50%);
}

.lc_tooltip-content--right:before {
  @apply border-b border-l left-0 top-1/2;
  transform: translate(-50%, -50%) rotate(45deg);
}

.lc_tooltip-content--left {
  @apply top-1/2;
  right: calc(100% + 10px);
  transform: translateY(-50%);
}

.lc_tooltip-content--left:before {
  @apply left-auto border-t border-r top-1/2;
  right: -0.85rem;
  transform: translate(-50%, -50%) rotate(45deg);
}

.lc_tooltip-content--small {
  @apply text-sm py-2 px-3;
}

.lc_tooltip-content--medium {
  @apply max-w-xs text-base p-4 text-center;
}

.lc_tooltip-content--full {
  @apply max-w-full;
}
</style>
