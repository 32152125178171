<script setup lang="ts">
const props = defineProps<{
  destination: string
  image: string
  name: string
  price: string
  slug: string
  wishlistActive: boolean
}>()

defineEmits<{
  'handle-wishlist': []
  'house-clicked-mobile': []
}>()

const localePath = useLocalePath()
const { isMobile } = useBreakpoint()

const housePath = computed(() =>
  localePath({
    name: 'house-slug',
    params: { slug: props.slug },
  }),
)
</script>

<template>
  <NuxtLink
    :to="housePath"
    :target="isMobile ? '_self' : '_blank'"
    class="relative flex rounded border border-gray-200 text-gray-700 hover:no-underline"
    @click="isMobile ? $emit('house-clicked-mobile') : undefined"
  >
    <span class="w-[88px] h-[88px] bg-gray-200">
      <BaseNuxtImg
        :src="image"
        alt="img"
        class="rounded-l"
        height="88"
        width="88"
      />
    </span>
    <span
      class="grow px-4 py-2 flex flex-col justify-center w-[calc(100%-88px)]"
    >
      <span class="font-monospace leading-6 truncate pr-3">
        {{ name }}
      </span>
      <span class="text-xs text-gray-600 truncate mb-2">
        {{ destination }}
      </span>
      <span class="text-xs font-bold truncate">
        {{ price }}
      </span>
    </span>
    <span class="absolute top-2 right-2">
      <BaseButton
        class="!border-0"
        :color="wishlistActive ? 'white' : 'black'"
        has-icon
        no-padding
        type="button"
        @click.prevent="$emit('handle-wishlist')"
      >
        <BaseIcon
          name="heart"
          :class="[
            'icon-transition',
            { 'fill-primary-500 stroke-primary-500': wishlistActive },
          ]"
          :stroke-width="1"
          :size="1"
        />
      </BaseButton>
    </span>
  </NuxtLink>
</template>
