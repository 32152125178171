<script setup lang="ts">
defineSlots<{ default(): any }>()

const router = useRouter()
const { headerDesktop, closeDropdown } = useHeaderDesktop()
const { headerNavigation, headerHeight, setNextRoute } = useHeaderNavigation()

const activeDropdown = computed(() => headerDesktop.value.activeDropdown)
const nextRoute = computed(() => headerNavigation.value.nextRoute)

onMounted(() => {
  document.addEventListener('scroll', onScroll)
})

onUnmounted(() => {
  document.removeEventListener('scroll', onScroll)
})

const onScroll = () => {
  if (activeDropdown.value.name && window.scrollY > headerHeight.value) {
    closeDropdown()
  }
}
const completedAnimation = () => {
  // trigger async navigation at the end of animation
  if (nextRoute.value) {
    router.push(nextRoute.value)
    setNextRoute(null)
  }
}
</script>

<template>
  <div class="absolute left-0 right-0 bg-white header-dropdown-wrapper">
    <transition name="expand" @after-leave="completedAnimation">
      <div
        v-show="activeDropdown.name"
        ref="dropdown"
        class="header-dropdown-body"
      >
        <slot />
      </div>
    </transition>

    <transition name="fade-overlay">
      <div
        v-if="activeDropdown.name"
        class="header-dropdown-overlay"
        @click="closeDropdown"
      />
    </transition>
  </div>
</template>

<style>
.header-dropdown-wrapper {
  top: var(--header-height);
}

.header-dropdown-overlay {
  content: '';
  z-index: -100;
  backdrop-filter: blur(10px);
  transition: opacity ease-in 300ms;
  @apply absolute w-full h-screen top-full right-0 left-0 bg-gray-700 bg-opacity-50;
}
.header-dropdown-body {
  height: 38rem;
  transition: height ease-in 350ms;
  @apply overflow-hidden;
}

.expand-enter-active,
.expand-leave-active {
  transition: height ease-in 350ms;
}
.expand-enter-to,
.expand-leave {
  height: 38rem;
}
.expand-enter,
.expand-leave-to {
  height: 0;
}
.fade-overlay-active,
.fade-overlay-active {
  opacity: 1;
}
.fade-overlay-enter,
.fade-overlay-leave-to {
  opacity: 0;
}
</style>
