import { ABTestService } from 'lc-repository'

import type { Experiment, UserVariant } from '~/types/amplitude/types'

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig()
  const ampCookie = useCookie('_amp')

  const { authUserIdTracking } = useAuth()
  const experiments = useState<Experiment[]>('experiments', () => [])
  const userVariant = useState<UserVariant>('user-variant')

  const ABTest = new ABTestService({
    amplitudeLabApiUrl: 'https://api.lab.eu.amplitude.com',
    experimentApiUrl: 'https://experiment.eu.amplitude.com',
    experimentApiKey: config.public.experimentApiKey,
    amplitudeApiUrl: 'https://api.eu.amplitude.com',
    amplitudeApiKey: config.public.amplitudeApiKey,
    httpClientType: 'ofetch',
  })

  const experimentServerInit = async () => {
    experiments.value =
      (await ABTest.getExperiments()) as unknown as Experiment[]
    userVariant.value = (await ABTest.getUserVariants({
      userId: authUserIdTracking.value,
      deviceId: ampCookie.value as string,
    })) as unknown as UserVariant
  }

  return {
    provide: {
      experimentServerInit,
    },
  }
})
