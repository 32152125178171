import type { Locale } from 'lc-services/types'

export const formatDate = (date: string, format = 'YYYY-MM-DD') =>
  $dayjs(date, 'YYYY-MM-DD').format(format)

export const formatTime = (time: string, locale: Locale) => {
  if (locale === 'fr') return time.split(':').join('h')
  const [hour, minute] = time.split(':').map(Number)
  const meridiem = hour / 12 >= 1 ? ' PM' : ' AM'
  if (hour === 0 || hour === 12)
    return `12:${String(minute).padStart(2, '0')}${meridiem}`
  return [hour % 12, String(minute).padStart(2, '0')].join(':').concat(meridiem)
}
