<script setup lang="ts">
import type { NuxtError } from '#app'

withDefaults(
  defineProps<{
    error?: NuxtError
  }>(),
  {
    error: () => ({}) as NuxtError,
  },
)

const localePath = useLocalePath()

const bgError =
  'https://cdn.lecollectionist.com/lc/production/assets/images/background-error.jpg'
</script>

<template>
  <div
    :style="{ backgroundImage: `url(${bgError})` }"
    class="flex flex-1 bg-cover py-16 min-h-[300px] md:min-h-[700px]"
  >
    <div class="lc-container flex items-center">
      <div>
        <h1 class="mb-8 font-normal uppercase max-w-[650px]">
          {{ $t('error_layout.title') }}
        </h1>
        <BaseButton :to="localePath('index')" size="lg">
          {{ $t('error_layout.btn') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>
