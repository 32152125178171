<script setup lang="ts">
import { ErrorMessage } from 'vee-validate'

defineOptions({
  inheritAttrs: false,
})

withDefaults(
  defineProps<{
    disabled?: boolean
    hasBorder?: boolean
    icon?: { name: IconPath; size: number; class?: string }
    infoText?: string
    inputHtml?: string
    label?: string
    labelHtml?: string
    name: string
    placeholder?: string
    rules?: string
    showCurrentFile?: boolean
    wrapperClass?: string
  }>(),
  {
    disabled: false,
    hasBorder: true,
    icon: undefined,
    infoText: '',
    inputHtml: '',
    label: '',
    labelHtml: '',
    placeholder: '',
    rules: '',
    showCurrentFile: false,
    wrapperClass: 'base-filedrop-input',
  },
)

defineSlots<{
  label(): any
}>()

const emits = defineEmits<{
  'update:model-value': [{ base64: string; mime_type: string; name: string }]
}>()

const filename = ref('')
const fileType = ref('')
const filePreview = ref('')
const baseFileDropInput = ref<HTMLInputElement>()
const reader = ref<FileReader>()

const openFileSelector = () => {
  if (baseFileDropInput.value) {
    baseFileDropInput.value.click()
  }
}
const onChange = (): void => {
  const input = baseFileDropInput.value as unknown as {
    files?: FileList
  }
  if (!input) return
  handleFilePreview(input.files)
}

const dragAndDrop = (e: DragEvent) => {
  if (!e.dataTransfer || !baseFileDropInput.value) return
  baseFileDropInput.value.files = e.dataTransfer.files
  handleFilePreview(e.dataTransfer.files)
}

const handleFilePreview = (files?: FileList) => {
  const [file] = files ?? []
  if (!file || !reader.value) return
  if (filename.value !== file.name) filename.value = file.name
  fileType.value = file.type
  reader.value.readAsDataURL(file)
}

const onLoadFile = () => {
  filePreview.value = (reader.value?.result ?? '').toString()
  if (filePreview.value && baseFileDropInput.value && fileType.value)
    emits('update:model-value', {
      base64: filePreview.value.split(';base64,')[1],
      name: filename.value,
      mime_type: fileType.value,
    })
}

onMounted(() => {
  reader.value = new FileReader()
  reader.value.addEventListener('load', onLoadFile)
})
onUnmounted(() => {
  reader.value?.removeEventListener('load', onLoadFile)
})
</script>

<template>
  <div :class="wrapperClass" @drop.prevent="dragAndDrop" @dragover.prevent>
    <slot v-if="!filePreview" name="label">
      <div
        class="!rounded-full bg-gray-100 w-[60px] h-[60px] flex justify-center items-center"
      >
        <BaseIcon v-if="icon" v-bind="icon" />
      </div>
      <label
        v-if="label || labelHtml"
        :class="`flex text-base text-center ${labelHtml ? 'flex-row justify-center gap-1' : 'flex-col items-center'}`"
        :for="name"
      >
        <BaseFormComponentsBaseFormLabel
          :label="label"
          :label-html="labelHtml"
        />
        <BaseFormComponentsBaseFormLabelRequired :rules="rules" />
        <BaseFormComponentsBaseFieldInfo
          v-if="infoText"
          class="!text-[14px]"
          :text="$t(infoText)"
        />
      </label>
    </slot>
    <embed
      v-else-if="fileType === 'application/pdf'"
      :src="filePreview"
      class="w-full h-full max-h-72 object-contain hover:opacity-75 duration-300 pointer-events-none"
      type="application/pdf"
    />
    <img
      v-else
      :src="filePreview"
      :alt="name"
      class="w-full h-full max-h-72 object-contain hover:opacity-75 duration-300 cursor-pointer"
    />

    <BaseButton
      color="secondary"
      class="w-56 border-gra text-center text-nowrap"
      size="sm"
      :variant="filePreview ? 'link' : 'outline'"
      @click="openFileSelector"
    >
      {{ $t(`action.${filePreview ? 'uploadAnother' : 'upload'}`) }}
    </BaseButton>

    <input
      :id="name"
      ref="baseFileDropInput"
      :accept="String($attrs.accept)"
      :name="name"
      class="hidden"
      type="file"
      @change="onChange"
    />

    <error-message :name="name" as="span" class="base-form--error" />
  </div>
</template>

<style>
.base-filedrop-input {
  @apply w-full mb-4 flex flex-col  gap-12 md:gap-8 py-16 md:py-10 border border-gray-200 items-center justify-center rounded;
}
</style>
